.slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide > .general {
  position: absolute;
}

.slide .container-text {
  color: #fff;
  width: 40%;
  position: absolute;
  z-index: 2;
  right: 50%;
  text-align: center;
}

.slide .main-text {
  font-weight: 700;
  font-size: 45px;
}

.slide .container-discount {
  font-size: 20px;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.slide .container-text .price {
  opacity: 0.4;
  font-size: 60px;
}

.slide .container-discount .old-price {
  text-decoration: line-through;
  font-size: 20px;
  opacity: 0.4;
}

.slide .container-discount .discount-value {
  margin-left: 5px;
  vertical-align: middle;
  height: auto;
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 2px;
  color: #de6c84;
  display: inline-block;
  font-weight: 400;
  line-height: 12px;
}

.slide .buy-button {
  padding: 15px 20px 15px 20px;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
  margin-top: 25px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  opacity: 0.8;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.slide .buy-button:hover {
  opacity: 1;
}

.slide .pre-text {
  opacity: 0.5;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}

.slide .pre-text:before {
  background-color: #fff;
  content: "";
  position: absolute;
  top: 50%;
  right: calc(100% + 11px);
  height: 1px;
  width: 19px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slide-filter1:before {
  background-image: url("../../../../images/img/pages/home/background-filter.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #f3f3f3;
  height: 100%;
}

.slide .container-image {
  z-index: 2;
  position: absolute;
  top: 20px;
  left: 50%;
  width: 40%;
  height: 100%;
}

// bubble
.slide .container-bubble {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 20%;
}

.slide .bubble-group.right {
  right: 55px;
}

.slide .bubble-group.left {
  left: 20px;
}

.slide .container-image .image {
  z-index: 1;
}

@media screen and (max-width: 970px) {
  .slide > .general {
    min-height: auto;
    margin-bottom: 20px;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .slide .container-text {
    top: initial;
    right: initial;
    position: relative;
    width: calc(100% - 50px);
    margin: auto;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    margin-top: 20px;
  }
  .slide .container-image {
    position: relative;
    width: calc(100% - 50px);
    top: initial;
    left: initial;
    margin: auto;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    margin-top: 20px;
  }

  .slide .main-text {
    font-size: 35px;
  }

  .slide .container-image img {
    max-height: 260px;
  }
}

.slide-idea {
  //background: linear-gradient(45deg, rgba(120, 131, 222, 0.08), #7f7bbbad)
  //background #ede7ee

  //background: -webkit-gradient(linear, left top, right top, from(#75469d), to(#ce7c70));
  //background: -webkit-linear-gradient(left, #75469d 0%, #ce7c70 100%);
  //background: -o-linear-gradient(left, #75469d 0%, #ce7c70 100%);
  //background: linear-gradient(90deg, #75469d 0%, #ce7c70 100%);
  background: #fef19f;

  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 80px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.slide-idea .bg-filter {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.slide-idea .bg-filter-custom {
  background-repeat: no-repeat;
  //background-position: center center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.slide-idea .content {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: center;
}

.slide-idea .left,
.slide-idea .right {
  position: relative;
  max-width: 320px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
  margin-left: 25px;
}
.slide-idea .right {
  max-width: 300px;
  max-height: 300px;
  height: 500px;
  width: 100%;
}

.slide-idea .right img {
  max-width: 300px;
  box-shadow: 0px 30px 17px -20px #523970;
}

.slide-idea .image {
  width: 100%;
  -webkit-transition: all 0.75s ease;
  -o-transition: all 0.75s ease;
  transition: all 0.75s ease;
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 0;
}

.slide-idea .image.show {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 0.9;
}

.slide-idea .left {
  max-width: 350px;
}

.slide-idea .title-block {
  margin-bottom: 10px;
}

.slide-idea .title-preview {
  opacity: 0.8;
  font-size: 16px;
  color: #e02b1f !important;
}

.slide-idea .title-title {
  line-height: 38px;
  font-size: 35px;
  color: #e02b1f !important;
}

.slide-idea .title-description {
  margin-top: 15px;
  font-size: 20px;
  color: black !important;
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  .slide-idea .bg-filter-custom {
    background-position: center;
  }
  .slide-idea {
    padding-top: 0;
    padding-bottom: 20px;
    //background: linear-gradient(180deg, #75469d 0%, #ce7c70 100%);
    //background #dbbfd3
  }
  .slide-idea {
    align-items: flex-start !important;
  }
  .slide-idea .content {
    flex-direction: column;
  }
  .slide-idea .left {
    margin: 0;
  }
  .slide-idea .title-title {
    line-height: 38px;
    font-size: 30px;
    //max-width 200px
  }
  .slide-idea .title-description {
    margin: 15px auto;
    //text-align left
    max-width: 300px;
    line-height: 26px;
  }

  .slide-idea .right {
    max-width: 250px;
    max-height: 250px;
  }

  .slide-idea .title-description li {
    list-style: circle inside;
  }
  .slide-idea .title-block {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .slide-idea .image {
    position: absolute;
    top: 0;
    width: 85%;
    transform: translateY(50px);
    //box-shadow 0px 30px 15px -30px #0c478d
  }

  .slide-idea .image.show {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.slide-idea.light .me-action-button {
  background-color: rgba(0, 0, 0, 0.1);
  color: #5e5d6d;
}

.slide-idea.light .me-action-button:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.slide-vegas {
  //background: linear-gradient(45deg, rgba(120, 131, 222, 0.08), #7f7bbbad)
  //background #ede7ee

  //background: -webkit-gradient(linear, left top, right top, from(#75469d), to(#ce7c70));
  //background: -webkit-linear-gradient(left, #75469d 0%, #ce7c70 100%);
  //background: -o-linear-gradient(left, #75469d 0%, #ce7c70 100%);
  //background: linear-gradient(90deg, #75469d 0%, #ce7c70 100%);
  background: #4f0016;

  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 80px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.slide-vegas .bg-filter {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  position: absolute;
  height: 100%;
  width: 100%;
}

.slide-vegas .bg-filter-custom {
  background-repeat: no-repeat;
  //background-position: center center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.slide-vegas .content {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: center;
}

.slide-vegas .left,
.slide-vegas .right {
  position: relative;
  max-width: 320px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
  margin-left: 25px;
}
.slide-vegas .right {
  max-width: 300px;
  max-height: 300px;
  height: 500px;
  width: 100%;
}

.slide-vegas .right img {
  max-width: 300px;
  //box-shadow: 0px 30px 17px -20px #523970;
}
// right

.slide-vegas .image {
  width: 100%;
  -webkit-transition: all 0.75s ease;
  -o-transition: all 0.75s ease;
  transition: all 0.75s ease;
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 0;
}

.slide-vegas .image.show {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 0.9;
}

// left

.slide-vegas .left {
  max-width: 350px;
}

.slide-vegas .title-block {
  margin-bottom: 10px;
}

.slide-vegas .title-preview {
  opacity: 0.8;
  font-size: 16px;
  color: #e02b1f !important;
}

.slide-vegas .title-title {
  line-height: 38px;
  font-size: 35px;
  color: #e02b1f !important;
}

.slide-vegas .title-description {
  margin-top: 15px;
  font-size: 20px;
  color: black !important;
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  .slide-vegas .bg-filter-custom {
    background-position: center;
  }
  .slide-vegas {
    padding-top: 0;
    padding-bottom: 20px;
    //background: linear-gradient(180deg, #75469d 0%, #ce7c70 100%);
    //background #dbbfd3
  }
  .slide-vegas {
    align-items: flex-start !important;
  }
  .slide-vegas .content {
    flex-direction: column;
  }

  .slide-vegas .left {
    margin: 0;
  }
  .slide-vegas .title-title {
    line-height: 38px;
    font-size: 30px;
    //max-width 200px
  }
  .slide-vegas .title-description {
    margin: 15px auto;
    //text-align left
    max-width: 300px;
    line-height: 26px;
  }

  .slide-vegas .button-container {
    justify-content: center;
    padding-bottom: 50px;
  }

  .slide-vegas .right {
    max-width: 250px;
    max-height: 250px;
  }

  .slide-vegas .right img {
    max-width: 250px;
  }

  .slide-vegas .title-description li {
    list-style: circle inside;
  }

  .slide-vegas .title-block {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .slide-vegas .image {
    position: absolute;
    top: 0;
    width: 85%;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    //box-shadow 0px 30px 15px -30px #0c478d
  }
  .slide-vegas .image.show {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.slide-vegas.light .me-action-button {
  background-color: rgba(0, 0, 0, 0.1);
  color: #5e5d6d;
}

.slide-vegas.light .me-action-button:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
