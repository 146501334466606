.first.slide-idea .content {
  justify-content: space-around;
}

.slide1 {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#75469d),
    to(#7177a7)
  );
  background: -webkit-linear-gradient(left, #75469d 0%, #7177a7 100%);
  background: -o-linear-gradient(left, #75469d 0%, #7177a7 100%);
  background: linear-gradient(90deg, #75469d 0%, #7177a7 100%);
}

.slide1 .container-text {
  color: #fff;
}

.slide1 .buy-button {
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 970px) {
  .slide1 .image {
    margin-top: 10px !important;
  }
}
