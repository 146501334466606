


.title-block.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title-block.preview-left {
    display: flex;
    flex-direction: row;
    align-items: flex-start
}

.title-block > div {
    max-width 100%
}

.title-block .title-preview {
    margin-bottom: 12px;
    font-size: 14px;
    display: block;
    font-weight: 700;
    letter-spacing: 0.15em;
}

.title-block .title-title {
    max-width: 100%;
    font-size: 32px;
    color: rgb(15, 33, 55);
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.025em;
}

.title-block .title-description {
    margin-bottom: 15px;
    margin-top: 30px;
    max-width: 100%;
    font-size: 16px;
    color: #000000;
    line-height: 28px;
}






@media screen and (max-width: 767px) {
    .title-block .title-title {
        font-size: 20px;
    }
    .title-block .title-preview {
        margin-bottom 0px
    }
}
