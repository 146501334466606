.product-gallery-item h2 {
  font-weight: 300 font-size 14px;
}

.image-feature {
  position: absolute;
  right: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 6px;
  padding-left: 6px;
  font-size: 13px;
  z-index: 20;
  color: white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  min-width: 40px;
}


.img-text-wrapper {
  position: absolute;
  top: 0;
  right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-text-wrapper-item {
  max-width: 44px;
  margin-top: 5px;
}