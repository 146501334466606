.slide-event {
  background: #fef19f;

  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 80px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.slide-event .bg-filter {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.slide-event .bg-filter-custom {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.slide-event .content {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: center;
}

.slide-event .left,
.slide-event .right {
  position: relative;
  max-width: 320px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
  margin-left: 25px;
}

.slide-event .right {
  max-width: 300px;
  max-height: 300px;
  height: 500px;
  width: 100%;
}

.slide-event .right img {
  max-width: 300px;
}

.slide-event .image {
  width: 100%;
  -webkit-transition: all 0.75s ease;
  -o-transition: all 0.75s ease;
  transition: all 0.75s ease;
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 0;
}

.slide-event .image.show {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 0.9;
}

.slide-event .left {
  max-width: 350px;
}

.slide-event .title-block {
  margin-bottom: 10px;
}

.slide-event .title-preview {
  opacity: 0.8;
  font-size: 16px;
  color: #e02b1f !important;
}

.slide-event .title-title {
  line-height: 38px;
  font-size: 35px;
  color: #e02b1f !important;
}

.slide-event .title-description {
  margin-top: 15px;
  font-size: 20px;
  color: black !important;
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  .slide-event .bg-filter-custom {
    background-position: center;
  }
  .slide-event {
    padding-top: 0;
    padding-bottom: 20px;
  }
  .slide-event {
    align-items: flex-start !important;
  }
  .slide-event .content {
    flex-direction: column;
  }
  .slide-event .left {
    margin: 0;
  }
  .slide-event .title-title {
    line-height: 38px;
    font-size: 30px;
  }
  .slide-event .title-description {
    margin: 15px auto;
    max-width: 300px;
    line-height: 26px;
  }

  .slide-event .button-container {
    justify-content: center;
    padding-bottom: 50px;
  }

  .slide-event .right {
    max-width: 250px;
    max-height: 250px;
  }

  .slide-event .right img {
    max-width: 250px;
  }

  .slide-event .title-description li {
    list-style: circle inside;
  }
  .slide-event .title-block {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .slide-event .image {
    position: absolute;
    top: 0;
    width: 85%;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
  }
  .slide-event .image.show {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.slide-event.light .me-action-button {
  background-color: rgba(0, 0, 0, 0.1);
  color: #5e5d6d;
}

.slide-event.light .me-action-button:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
