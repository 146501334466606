.carousel-next-button {
  position: absolute;
  z-index: 2;
  top: calc(50% - 50px);
  right: 30px;
  height: 35px;
  width: 35px;
  border-radius: 30px;
  cursor: pointer;
  opacity: 0.6;
  background-color: rgba(255, 255, 255, 0.2);
}

.carousel-next-button:hover {
  opacity: 1;
}

.carousel-next-button .arrow {
  stroke: none !important;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.carousel-next-button .progress {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 38px;
  width: 38px;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  -ms-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
}

.carousel-next-button .progress circle {
  fill: none !important;
  stroke-width: 4px;
  stroke-dasharray: 339.292;
  stroke-dashoffset: 339.292;
  -webkit-transition: stroke-dashoffset 0s ease;
  -o-transition: stroke-dashoffset 0s ease;
  transition: stroke-dashoffset 0s ease;
}

.carousel-next-button .progress.active circle {
  -webkit-transition: stroke-dashoffset 10s linear !important;
  -o-transition: stroke-dashoffset 10s linear !important;
  transition: stroke-dashoffset 10s linear !important;
  stroke-dashoffset: 0;
}

.carousel-next-button .arrow,
.carousel-next-button .progress circle {
  fill: #fff;
  stroke: #fff;
}

@media screen and (max-width: 970px) {
  .carousel-next-button {
    display: none;
  }
}

.carousel-dark .carousel-next-button {
  background-color: rgba(54, 63, 84, 0.2);
}

.carousel-dark .carousel-next-button .arrow,
.carousel-dark .carousel-next-button .progress circle {
  fill: #363f54;
  stroke: #363f54;
}
