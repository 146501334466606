.menu-page #sales-section .item-gallery-header {
  margin-bottom: 0px !important;
}

@media only screen and (min-width: 768px) {
  .menu-page #sales-section .container {
    padding: 0 !important;
    margin-left: -5px;
    margin-right: -5px;
  }
}
