#carousel-section {
  padding-top: 0px;
  padding-bottom: 20px;
}

.slide-ivermag {
  background: linear-gradient(315.12deg, #2a295c 0%, #8282dc 100%);
}

.slide-magestrofan {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#75469d),
    to(#7177a7)
  );
  background: -webkit-linear-gradient(left, #75469d 0%, #7177a7 100%);
  background: -o-linear-gradient(left, #75469d 0%, #7177a7 100%);
  background: linear-gradient(90deg, #75469d 0%, #7177a7 100%);
}

.slide-ivermek {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5e3084),
    to(#2fad7e)
  );
  background: -webkit-linear-gradient(left, #5e3084 0%, #2fad7e 85%);
  background: -o-linear-gradient(left, #5e3084 0%, #2fad7e 85%);
  background: linear-gradient(90deg, #5e3084 0%, #2fad7e 85%);
}

.slide-urotropin {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5e3084),
    to(#6074da)
  );
  background: -webkit-linear-gradient(left, #5e3084 0%, #6074da 80%);
  background: -o-linear-gradient(left, #5e3084 0%, #6074da 80%);
  background: linear-gradient(90deg, #5e3084 0%, #6074da 80%);
}

.slide-urotropin .image img {
  width: 60%;
}

.slide-enroflon {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5e3084),
    to(#73a9d5)
  );
  background: -webkit-linear-gradient(left, #5e3084 0%, #73a9d5 70%);
  background: -o-linear-gradient(left, #5e3084 0%, #73a9d5 70%);
  background: linear-gradient(90deg, #5e3084 0%, #73a9d5 70%);
}

.slide-enroflon .image img {
  width: 70%;
}

.slide-baitril {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#75469d),
    to(#4a8edc)
  );
  background: -webkit-linear-gradient(left, #75469d 0%, #4a8edc 80%);
  background: -o-linear-gradient(left, #75469d 0%, #4a8edc 80%);
  background: linear-gradient(90deg, #75469d 0%, #4a8edc 80%);
}

.slide-baitril .image img {
  width: 80%;
}

.slide-eleovit {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#75469d),
    to(#ce7c70)
  );
  background: -webkit-linear-gradient(left, #75469d 0%, #ce7c70 100%);
  background: -o-linear-gradient(left, #75469d 0%, #ce7c70 100%);
  background: linear-gradient(90deg, #75469d 0%, #ce7c70 100%);
}

.slide-eleovit .image img {
  width: 85%;
}

@media screen and (max-width: 970px) {
  #carousel-section {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .slide-unique .image img {
    width: auto;
    height: 100%;
    max-height: 260px;
  }

  .slide-enroflon {
    background: linear-gradient(180deg, #5e3084 0%, #73a9d5 100%);
  }

  .slide-magestrofan {
    background: linear-gradient(180deg, #75469d 0%, #7177a7 100%);
  }

  .slide-ivermek {
    background: linear-gradient(180deg, #5e3084 0%, #2fad7e 100%);
  }

  .slide-urotropin {
    background: linear-gradient(180deg, #5e3084 0%, #6074da 100%);
  }

  .slide-baitril {
    background: linear-gradient(180deg, #75469d 0%, #4a8edc 100%);
  }

  .slide-eleovit {
    background: linear-gradient(180deg, #75469d 0%, #ce7c70 100%);
  }
}
