#sales-section {
  padding-top: 0px;
  padding-bottom: 60px;
  margin-top: 0px;
}

#sales-section .item-gallery-header {
  margin-bottom: 15px !important;
}

@media only screen and (max-width: 768px) {
  #sales-section {
    margin-top: 0px;
    padding-bottom: 40px;
  }

  #sales-section .item-gallery-header {
    margin-bottom: 0px !important;
  }
}


.product-list-page .tmallItem.product-gallery-item {
  border-radius: 5px;
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.05);
  background-color: #ffff !important;
}

.product-list-page .product-grid-tmall .item {
  width: 25%;
  min-width: 25%;
  padding: 8px;
}

.product-list-page .product-grid-tmall.with-filter .item {
  width: 25%;
  min-width: 25%;
}


@media only screen and (max-width: 1200px) {
  .product-list-page .product-grid-tmall .item {
    width: 25%;
    min-width: 25%;
  }
  .product-list-page .product-grid-tmall.with-filter .item {
    width: 25%;
    min-width: 25%;
  }
}

@media only screen and (max-width: 1000px) {
  .product-list-page .product-grid-tmall .item {
    width: 25%;
    min-width: 25%;
  }
  .product-list-page .product-grid-tmall.with-filter .item {
    width: 33.33333%;
    min-width: 33.33333%;
  }
}
